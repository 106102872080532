type Props = {
	stats: {
		total: number;
		arrivals: number;
		departures: number;
	};
};

export const VesselStatistics = ({ stats }: Props) => {
	return (
		<>
			<div className="flex justify-between">
				<span>Number of crew</span>
				<span>{stats.total}</span>
			</div>
			<div className="flex justify-between">
				<span>Arrivals</span>
				<span>{stats.arrivals}</span>
			</div>
			<div className="flex justify-between">
				<span>Departures</span>
				<span>{stats.departures}</span>
			</div>
		</>
	);
};
