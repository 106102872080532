import { apiSlice } from "@/app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation({
			query: ({ email, password, loginType }) => ({
				url: "/api/auth/login",
				method: "POST",
				body: { email, password, loginType },
			}),
		}),
		magicLogin: builder.mutation({
			query: ({ token }) => ({
				url: `/api/auth/login-token`,
				method: "POST",
				body: { token },
			}),
		}),
		logout: builder.mutation({
			query: () => ({
				url: "/api/auth/logout",
				method: "POST",
			}),
		}),
		forgotPassword: builder.mutation({
			query: ({ email }) => ({
				url: "/api/auth/password/email",
				method: "POST",
				body: { email },
			}),
		}),
		setPassword: builder.mutation({
			query: ({ token, email, password, confirmPassword, regulationsAcceptance }) => ({
				url: "/api/auth/password/reset",
				method: "POST",
				body: { token, email, password, confirmPassword, regulationsAcceptance },
			}),
		}),
		getCurrentUser: builder.query({
			query: () => `/api/profile`,
			providesTags: ["CurrentUser"],
		}),
		sendEmailToSetPassword: builder.mutation({
			query: ({ email }) => ({
				url: "/api/auth/password/email-send",
				method: "POST",
				body: { email },
			}),
		}),
	}),
	overrideExisting: true,
});

export const {
	useLoginMutation,
	useMagicLoginMutation,
	useLogoutMutation,
	useForgotPasswordMutation,
	useSetPasswordMutation,
	useGetCurrentUserQuery,
	useSendEmailToSetPasswordMutation,
} = authApiSlice;
