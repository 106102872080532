import { useSendEmailToSetPasswordMutation } from "@/app/api/slices/authApiSlice";
import { Button } from "@/components/ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";
import { Loader, Send } from "lucide-react";

type Props = {
	userEmail: string;
	emailVerifiedAt: string | null;
};

export const SendSetPasswordEmail = ({ userEmail, emailVerifiedAt }: Props) => {
	// to-do lock button if user already set password
	const { toast } = useToast();
	const [sendEmailMutation, { isLoading }] = useSendEmailToSetPasswordMutation();

	const handleSendEmail = async () => {
		await sendEmailMutation({ email: userEmail })
			.unwrap()
			.then((_payload) => {
				toast({
					variant: "success",
					title: "Email has been sent.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	const hasEmail = userEmail !== null;
	const isVerified = emailVerifiedAt !== null;

	return (
		<TooltipProvider>
			<Tooltip delayDuration={0}>
				<TooltipTrigger asChild>
					<Button
						variant="outline"
						size="icon"
						style={{ background: "#9c1ee9" }}
						disabled={!hasEmail || isVerified} // only enable button if user has email and email is not verified
						onClick={handleSendEmail}
					>
						{isLoading ? (
							<Loader color="#fff" className="h-4 w-4 animate-spin duration-2000" />
						) : (
							<Send color="#fff" />
						)}
					</Button>
				</TooltipTrigger>
				<TooltipContent>
					<p>Send email to set password</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
};
