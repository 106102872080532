import * as z from "zod";

import { emailZodRule, firstNameZodRule, lastNameZodRule, phoneZodRule } from "@/lib/zod";

export const baseSchema = z.object({
	firstName: firstNameZodRule,
	lastName: lastNameZodRule,
	email: z.string(),
	role: z.string().min(1, { message: "Role is required" }),
	phone: phoneZodRule,
	nationality: z.string().min(1, { message: "Nationality is required" }),
	homeAirport: z.string().min(1, { message: "Home airport is required" }),
	department: z.string().min(1, { message: "Department is required" }),
	position: z.string().min(1, { message: "Position is required" }),
});

export const nonAdminSchema = baseSchema.extend({
	email: emailZodRule, // Email is required
});

export const adminSchema = baseSchema.extend({
	email: z.string().email({ message: "Invalid email address" }).optional().or(z.literal("")), // Email is optional
});

export type FormType = z.infer<typeof baseSchema>;
export type AdminFormType = z.infer<typeof adminSchema>;

export const getFormSchema = (isAdmin: boolean) => (isAdmin ? adminSchema : nonAdminSchema);
