import { endOfMonth } from "date-fns";
import { ChevronsUpDown, Loader, MoveVertical } from "lucide-react";
import { Link } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { useDateFormatter } from "@/hooks/useDateFormatter";
import { type StringOpenStates } from "@/types/AccordionOpenStates";

type Props = {
	openStates: StringOpenStates;
	toggleOpenState: (sectionId: string) => void;
	departments: {
		id: number;
		name: string;
		positions: {
			id: number;
			name: string;
			crew: {
				userId: number;
				name: string;
				rotationPeriod: string;
				leave_balance_1: number;
				leave_balance_2: number;
			}[][];
		}[];
	}[];
	leaveBalanceType: number;
	isLoading: boolean;
	selectedDate: Date | undefined;
};

export const VesselCrewAccordion = ({
	openStates,
	toggleOpenState,
	departments,
	leaveBalanceType,
	isLoading,
	selectedDate,
}: Props) => {
	const { format } = useDateFormatter();

	if (isLoading) {
		return (
			<div className="flex h-24 w-full items-center justify-center">
				<Loader className="animate-spin duration-2000" color="#9ca3af" />
			</div>
		);
	}

	return (
		<div className="flex flex-col gap-3">
			{departments.map((department, index) => {
				return (
					<Collapsible
						key={department.id}
						open={openStates[`dep-${index}`] || false}
						onOpenChange={() => toggleOpenState(`dep-${index}`)}
						className="space-y-2"
					>
						<div className="flex items-center justify-between space-x-4">
							<CollapsibleTrigger asChild>
								<Button
									variant="ghost"
									size="sm"
									className="flex w-full items-center justify-between"
								>
									<h4 className="text-sm font-semibold">{department.name}</h4>
									<ChevronsUpDown className="h-4 w-4" />
									<span className="sr-only">Toggle</span>
								</Button>
							</CollapsibleTrigger>
						</div>
						<CollapsibleContent className="overflow-x-scroll">
							<table className="w-full caption-bottom border-separate text-sm">
								<thead>
									<tr>
										<th className="rounded-md bg-gray-400 py-1 font-normal">Position</th>
										<th className="rounded-md bg-gray-400 py-1 font-normal">Name</th>
										<th className="rounded-md bg-gray-400 py-1 font-normal">
											Arrival/Departure days
										</th>
										<th className="rounded-md bg-gray-400 py-1 font-normal">Leave Balance</th>
									</tr>
								</thead>
								<tbody>
									{department.positions.length > 0 &&
										department.positions.map((position) => {
											return position.crew.map((group) => {
												const sortedGroup = [...group].sort((a, b) => {
													const isAOnboard = isMemberOnboard(a);
													const isBOnboard = isMemberOnboard(b);

													if (isAOnboard && !isBOnboard) return -1;
													if (!isAOnboard && isBOnboard) return 1;
													return 0;
												});

												return sortedGroup.map((member, index) => {
													const isOnboard = selectedDate
														? willMemberOnboardInSelectedDate(member, selectedDate)
														: isMemberOnboard(member);

													const isArrivalOnCurrentMonthOrSelectedDate = selectedDate
														? willMemberArriveInSelectedDate(member, selectedDate)
														: willMemberArriveInSelectedDate(member, new Date());

													const isDepartureOnCurrentMonthOrSelectedDate = selectedDate
														? willMemberDepartureInSelectedDate(member, selectedDate)
														: willMemberDepartureInSelectedDate(member, new Date());

													const rotationPeriod = member.rotationPeriod?.split(" - ");

													return (
														<tr key={member.name} className="group relative">
															<td
																className={`rounded-md py-1 text-center ${
																	isOnboard
																		? "bg-green-500/20 group-hover:bg-green-500/40"
																		: "bg-red-500/20 group-hover:bg-red-500/40"
																}`}
															>
																<Link
																	to={`/positions`}
																	target="_blank"
																	className="vessel-departments-statistics-big-box-position"
																>
																	{position.name}
																</Link>
															</td>
															<td
																className={`rounded-md py-1 text-center ${
																	isOnboard
																		? "bg-green-500/20 group-hover:bg-green-500/40"
																		: "bg-red-500/20 group-hover:bg-red-500/40"
																}`}
															>
																<Link
																	to={`/crew-members/${member.userId}/rotation-calendar`}
																	target="_blank"
																	className="vessel-departments-statistics-big-box-member"
																>
																	{member.name}
																</Link>
															</td>
															<td
																className={`whitespace-nowrap rounded-md py-1 text-center ${
																	isOnboard
																		? "bg-green-500/20 group-hover:bg-green-500/40"
																		: "bg-red-500/20 group-hover:bg-red-500/40"
																}`}
															>
																{(() => {
																	if (!rotationPeriod) return "-";

																	const [startDate, endDate] = rotationPeriod;

																	const arrivalSpan = (
																		<span className="inline-block rounded-sm border border-[#fb8500] px-2 py-0.5">
																			Arrival - {format(startDate)}
																		</span>
																	);

																	const departureSpan = (
																		<span className="inline-block rounded-sm border border-[#fb8500] px-2 py-0.5">
																			{format(endDate)} - Departure
																		</span>
																	);

																	const flexContainerClasses =
																		"flex items-center justify-center sm:space-x-2";
																	const flexItemClasses = "flex-0";

																	if (
																		isArrivalOnCurrentMonthOrSelectedDate &&
																		isDepartureOnCurrentMonthOrSelectedDate
																	) {
																		return (
																			<div className={flexContainerClasses}>
																				<span className={`${flexItemClasses} text-right`}>
																					{arrivalSpan}
																				</span>
																				<span className="mx-2">-</span>
																				<span className={`${flexItemClasses} text-left`}>
																					{departureSpan}
																				</span>
																			</div>
																		);
																	}

																	if (isArrivalOnCurrentMonthOrSelectedDate) {
																		return (
																			<div className={flexContainerClasses}>
																				<span className={`${flexItemClasses} text-right`}>
																					{arrivalSpan}
																				</span>
																				{/* <span className="mx-2">-</span>
																				<span className={`${flexItemClasses} text-left`}>
																					{format(endDate)}
																				</span> */}
																			</div>
																		);
																	}

																	if (isDepartureOnCurrentMonthOrSelectedDate) {
																		return (
																			<div className={flexContainerClasses}>
																				{/* <span className={`${flexItemClasses} text-right`}>
																					{format(startDate)}
																				</span>
																				<span className="mx-2">-</span> */}
																				<span className={`${flexItemClasses} text-left`}>
																					{departureSpan}
																				</span>
																			</div>
																		);
																	}

																	return "-";
																})()}
															</td>
															<td
																className={`vessel-departments-statistics-big-box-leave-balance rounded-md py-1 text-center ${
																	isOnboard
																		? "bg-green-500/20 group-hover:bg-green-500/40"
																		: "bg-red-500/20 group-hover:bg-red-500/40"
																}`}
															>
																{leaveBalanceType === 1 && member.leave_balance_1}
																{leaveBalanceType === 2 && member.leave_balance_2}
																{leaveBalanceType === 3 && (
																	<>
																		<div>{member.leave_balance_1}</div>
																		<div>{member.leave_balance_2}</div>
																	</>
																)}
															</td>
															{index === 1 && (
																<div className="absolute right-0 top-0 -translate-y-1/2">
																	<MoveVertical color="#6b7280" />
																</div>
															)}
														</tr>
													);
												});
											});
										})}
								</tbody>
							</table>
						</CollapsibleContent>
					</Collapsible>
				);
			})}
		</div>
	);
};

const isMemberOnboard = (member: {
	userId: number;
	name: string;
	rotationPeriod: string;
	leave_balance_1: number;
	leave_balance_2: number;
}) => {
	const today = stripTime(new Date());
	const rotationDatesArray = member.rotationPeriod !== null && member.rotationPeriod.split(" - ");
	return (
		rotationDatesArray &&
		today >= stripTime(new Date(rotationDatesArray[0])) &&
		today <= stripTime(new Date(rotationDatesArray[1]))
	);
};

const willMemberOnboardInSelectedDate = (
	member: {
		userId: number;
		name: string;
		rotationPeriod: string;
		leave_balance_1: number;
		leave_balance_2: number;
	},
	date: Date,
) => {
	const parsedDate = stripTime(endOfMonth(date));
	const rotationDatesArray = member.rotationPeriod !== null && member.rotationPeriod.split(" - ");
	return rotationDatesArray && parsedDate >= stripTime(new Date(rotationDatesArray[0]));
};

const willMemberArriveInSelectedDate = (
	member: {
		userId: number;
		name: string;
		rotationPeriod: string;
		leave_balance_1: number;
		leave_balance_2: number;
	},
	date: Date,
) => {
	const parsedDate = stripTime(endOfMonth(date));
	const rotationDatesArray = member.rotationPeriod !== null && member.rotationPeriod.split(" - ");
	const rotationPeriodMonth = rotationDatesArray && new Date(rotationDatesArray[0]).getMonth();
	const rotationPeriodYear = rotationDatesArray && new Date(rotationDatesArray[0]).getFullYear();
	const selectedDateMonth = parsedDate.getMonth();
	const selectedDateYear = parsedDate.getFullYear();
	return (
		rotationDatesArray &&
		rotationPeriodMonth === selectedDateMonth &&
		rotationPeriodYear === selectedDateYear
	);
};

const willMemberDepartureInSelectedDate = (
	member: {
		userId: number;
		name: string;
		rotationPeriod: string;
		leave_balance_1: number;
		leave_balance_2: number;
	},
	date: Date,
) => {
	const parsedDate = stripTime(endOfMonth(date));
	const rotationDatesArray = member.rotationPeriod !== null && member.rotationPeriod.split(" - ");
	const rotationPeriodMonth = rotationDatesArray && new Date(rotationDatesArray[1]).getMonth();
	const rotationPeriodYear = rotationDatesArray && new Date(rotationDatesArray[1]).getFullYear();
	const selectedDateMonth = parsedDate.getMonth();
	const selectedDateYear = parsedDate.getFullYear();
	return (
		rotationDatesArray &&
		rotationPeriodMonth === selectedDateMonth &&
		rotationPeriodYear === selectedDateYear
	);
};

const stripTime = (date: Date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());
